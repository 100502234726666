var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ControlPanelContentFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Graph Info ")]},proxy:true}])},[_c('q-table',{staticClass:"custom-table",attrs:{"data":_vm.tableContent,"columns":_vm.columns,"pagination":_vm.pagination,"loading":_vm.loadingContent,"no-data-label":"No graph info is found.","row-key":"id","separator":"cell"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('RefreshButton',{staticClass:"q-mr-sm",attrs:{"fetch-func":_vm.fetchGraphInfo}}),_c('LangSelector',{attrs:{"current-lang":_vm.tableLang,"change-callback":_vm.changeTableLang,"show-header":true}})]},proxy:true},{key:"header",fn:function(props){return [_c('AllTableHeader',{attrs:{"passed-props":props}})]}},{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-td',{key:"title",attrs:{"props":props}},[_c('OpenInEditorButton',{attrs:{"label":props.row.title,"routePath":{
              name: 'Graph Info Editor',
              params: {
                anchorId: props.row.graphId,
                contentId: props.row.id,
              },
              query: {
                lang: _vm.tableLang,
                graphUrl: props.row.graphUrl,
              },
            }}})],1),_c('q-td',{key:"isPublished",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.isPublished ? '✅' : '❌')+" ")]),_c('q-td',{key:"abstract",attrs:{"props":props}},[_c('q-input',{attrs:{"type":"textarea","readonly":""},model:{value:(props.row.abstract),callback:function ($$v) {_vm.$set(props.row, "abstract", $$v)},expression:"props.row.abstract"}})],1),_c('q-td',{key:"graphName",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.graphName)+" ")]),_c('q-td',{key:"graphUrl",attrs:{"props":props}},[_c('OpenInPageButton',{attrs:{"label":props.row.graphUrl,"routePath":{
              name: 'Graph',
              params: { lang: _vm.tableLang, url: props.row.graphUrl },
            }}})],1),_c('q-td',{key:"id",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.id)+" ")]),_c('DeleteTableCell',{attrs:{"message":("Do you want to delete graph translation '" + (props.row.title) + " that belongs to graph '" + (props.row.graphName) + "' with id '" + (props.row.id) + "'?"),"id":props.row.id,"content-type":_vm.currentDeletionContentType,"final-callback":_vm.fetchGraphInfo}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }